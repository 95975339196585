import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

import {makeStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'

import {MenuItemComponent} from '../MenuItemComponent'
import Three from './Three';


const useStyles = makeStyles(theme => ({
    menuItem: {
        '&.active': {
            color: theme.palette.quaternary.main,
            '& .MuiListItemIcon-root': {
                color: '#fff',
            },
        },
    },
    menuItemIcon: {
        color: '#97c05c',
    },
    menuLink: {
        fontSize: '0.7rem',
        paddingLeft: '10px'
    },
    bullet: {
        width: '4px',
        height: '4px',
        background: theme.palette.neon.main,
        borderRadius: '100%',
        marginLeft: '28px'
    },
    expand: {
        fontSize: '1rem'
    }
}));

export const AppMenuItemPropTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    items: PropTypes.array,
    permissions: PropTypes.array
}

function Two(props) {
    const {name, link, items = [], permissions, user} = props
    const classes = useStyles()
    const isExpandable = items && items.length > 0
    const hasPermission = permissions.includes(user.role)
    const [open, setOpen] = React.useState(false)

    function handleClick() {
        setOpen(!open)
    }

    const MenuItemRoot = (
        <>
            {!!hasPermission && (
                <MenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
                    <span className={classes.bullet}></span>
                    <ListItemText disableTypography primary={<FormattedMessage id={name}/>}
                                  className={classes.menuLink}/>
                    {isExpandable && !open && <IconExpandMore className={classes.expand}/>}
                    {isExpandable && open && <IconExpandLess className={classes.expand}/>}
                </MenuItemComponent>
            )}
        </>
    )

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider/>
            <List component="div" disablePadding>
                {items.map((item, index) => (
                    <Three {...item} key={index} className={classes.childItem}/>
                ))}
            </List>
        </Collapse>
    ) : null

    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    )
}

Two.propTypes = AppMenuItemPropTypes

const mapStateToProps = ({auth: {user}}) => ({user});

export default connect(
    mapStateToProps
)(Two);