// Base react components needs to be imported first
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Import Redux (https://react-redux.js.org)
import store, {persistor} from './store/store';

// Import Axios (https://www.npmjs.com/package/react-axios)
import axios from 'axios';
import axiosInit from './utils/axiosInit'

// Import theme components
import {SplashProvider} from "./components/layout";

// Import actual application
import Root from './components/environment/Root';


// Initialise axios interceptor for authentication
axiosInit(axios, store);

// Base url of the website
const {PUBLIC_URL} = process.env;

ReactDOM.render(
  <SplashProvider>
  <Root store={store} persistor={persistor} basename={PUBLIC_URL}/>
  </SplashProvider>,
  document.getElementById('app'),
);