import React from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {actions} from "../../../../../store/reducers/i18n";
import {ListItemIcon} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    listItemIcon: {
      minWidth: 0
    },
    itemIcon: {
        borderRadius: '10px'
    },
    selectItemIcon: {
        borderRadius: '10px',
        marginRight: '7px'
    }
}));

const languages = [
    {
        short: 'en',
        name: 'English',
        icon: '/icons/united-kingdom.svg'
    }
];

function LanguageSelectMenu(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { lang, setLanguage } = props;
    const currentLanguage = languages.find(x => x.short === lang);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <List component="nav">
                <ListItem
                    button
                    aria-haspopup="true"
                    onClick={handleClickListItem}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <img src={currentLanguage.icon} height={25} className={classes.itemIcon}/>
                    </ListItemIcon>
                </ListItem>
            </List>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {languages.map((language) => (
                    <MenuItem
                        key={language.short}
                        selected={language.short === currentLanguage.short}
                        onClick={() => {
                            setLanguage(language.short);
                            //this.setState({ open: false });
                            setTimeout(()=> window.location.reload(), 400);
                        }}
                    >
                        <img src={language.icon} height={20} className={classes.selectItemIcon} /> {language.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang });

export default connect(
    mapStateToProps,
    actions
)(LanguageSelectMenu);