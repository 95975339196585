import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {GuardAdmin, GuardNotAdmin} from "../../helpers";
import {CredentialProvider} from "react-rbac-guard";
import AdminUserManagement from "./pages/users/AdminUserManagement";
import {GlobalSettings} from "./pages/GlobalSettings";
import {ContentRoute} from "../../layout";
import ErrorsPage from "../errors";
import AdminUserCreate from "./pages/users/AdminUserCreate";
import AdminUserEdit from "./pages/users/AdminUserEdit";


function Administration(props) {
    const {user} = props;

    return(
        <CredentialProvider value={user.role}>
            <GuardAdmin>
                <Switch>
                    {/* User management routes*/}
                    <ContentRoute exact path="/administration/users" component={AdminUserManagement}/>}
                    <ContentRoute exact path="/administration/users/create" component={AdminUserCreate}/>}
                    <ContentRoute exact path="/administration/users/:id/edit" component={AdminUserEdit}/>}

                    <ContentRoute exact path="/administration/settings" component={GlobalSettings}/>}
                    <Route path="*" component={ErrorsPage} />
                </Switch>
            </GuardAdmin>
            <GuardNotAdmin>
                <Redirect to="/error/407" />
            </GuardNotAdmin>
        </CredentialProvider>
    );
}

const mapStateToProps = ({auth: {user}}) => ({user});

export default connect(
    mapStateToProps
)(Administration);
