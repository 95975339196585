import React, {useEffect} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Card,
    CardContent,
    Chip,
    Link,
    Slider,
    Typography
} from "@material-ui/core";

import {connect, useDispatch, useSelector} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import clsx from "clsx";
import {green} from "@material-ui/core/colors";
import MuiAlert from '@material-ui/lab/Alert';

import * as snackbar from "../../../../../store/reducers/snackbar";
import store from '../../../../../store/store';
import {connectBlockchain} from "../../../../../utils/blockchain"
import {getMinterInformation} from "../../../../../utils/contract";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#201E20',
        minHeight: '200px !important',
        maxHeight: 'auto',
        width: '100vw',
        display: 'flex',
        WebkitBoxAlign: "center",
        alignItems: "center",
        WebkitBoxPack: "justify",
        justifyContent: "center"
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    card: {
        minWidth: '260px',
        paddingLeft: '24px',
        paddingRight: '24px',
        margin: '24px',
        backgroundColor: theme.palette.quaternary.main
    },
    cardContent: {
        textAlign: "center"
    },
    slider: {
        marginTop: '34px'
    },
    chip: {
        marginBottom: '24px',
        marginTop: '12px',
        fontSize: 14
    },
    feedback: {
        marginTop: '24px'
    }
}));

function Minter(props) {
    const {intl} = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const blockchainData = useSelector((state) => state.blockchain);
    const contractData = useSelector((state) => state.contract);

    // Connected
    const [connected, setConnected] = React.useState(false);

    // Connect Button
    const [loading, setLoading] = React.useState(false);

    // Feedback Minter
    const [feedback, setFeedback] = React.useState('MINTER.FEEDBACK.DEFAULT');
    const [feedbackSeverity, setFeedbackSeverity] = React.useState('info');

    // mintAmount
    const [mintAmount, setMintAmount] = React.useState(1);

    // Mint Button
    const [loadingMint, setLoadingMint] = React.useState(false);
    const [successMint, setSuccessMint] = React.useState(false);

    const buttonClassnameMint = clsx({
        [classes.buttonSuccess]: successMint,
    });

    // Slider
    function minterValueText(value) {
        return `${value} ${contractData.nftSymbol}`;
    }

    const minterMarks = [
        {
            value: 1,
            label: `1 ${contractData.nftSymbol}`,
        },
        {
            value: 10,
            label: `10 ${contractData.nftSymbol}`,
        }
    ];

    const claimNFTs = () => {
        let gasLimit = process.env.BLOCKCHAIN_GAS_LIMIT;
        let totalCostWei = String(contractData.cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);

        setFeedback('MINTER.FEEDBACK.MINTING');
        setFeedbackSeverity('warning');

        setLoadingMint(true)
        store.getState().blockchain.smartContract.methods
            .publicMint(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: process.env.BLOCKCHAIN_CONTRACT_AT,
                from: blockchainData.account,
                value: totalCostWei,
            })
            .once("error", () => {
                setFeedback("MINTER.FEEDBACK.ERROR");
                setFeedbackSeverity('error');
                setLoadingMint(false);
            })
            .then(() => {
                setFeedback('MINTER.FEEDBACK.SUCCESS');
                setFeedbackSeverity('success');
                setSuccessMint(true);
                setLoadingMint(false)
                getMinterInformation();
                setMintAmount(1);
            });
    };

    const getSmartContractData = async() => {
        if (blockchainData.account !== "" && blockchainData.smartContract !== null) {
            let result = await getMinterInformation();

            if(result.success){
                setConnected(true)
            }

            setLoading(false)

            dispatch(snackbar.actions.show(result.type, intl.formatMessage({
                id: result.id
            })));
        }
    };

    const connectBlockchainWrapper = async() => {
        setLoading(true)
        let result = await connectBlockchain()

        if(result.success === false){
            if(result.network.length > 0) {
                dispatch(snackbar.actions.show(result.type, intl.formatMessage({
                    id: result.id
                }, {
                    network: result.network
                })));
            } else {
                dispatch(snackbar.actions.show(result.type, intl.formatMessage({
                    id: result.id
                })));
            }

            setLoading(false)
        }
    }

    useEffect(() => {
        getSmartContractData();
    }, [blockchainData.account]);

    return (
        <div className={classes.root}>
            {connected ? (
                <>
                    {contractData.status === false ? (
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h5" component="div">
                                    Launch on 25th of February 2022.
                                </Typography>
                            </CardContent>
                        </Card>
                    ) : (
                        <>
                            {Number(contractData.totalSupply) >= contractData.mintSize ? (
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography variant="h5" component="div">
                                            This sale has ended.
                                        </Typography>
                                        <Typography ariant="body2">
                                            You can still find {contractData.nftName} on <Link target={"_blank"}
                                                                                               href={process.env.BLOCKCHAIN_MARKET_PLACE_LINK}>{process.env.BLOCKCHAIN_MARKET_PLACE_NAME}</Link>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ) : (
                                <Card className={classes.card}>
                                    <CardContent className={classes.cardContent}>

                                        <Chip label={`${parseInt(contractData.totalSupply)+1} / ${parseInt(contractData.mintSize)+1}`}
                                              color="primary" className={classes.chip}/>

                                        <Typography variant="h5">
                                            Buy
                                            1 {contractData.nftSymbol} for {contractData.cost / 10 ** 18} FTM
                                        </Typography>

                                        <Typography variant="caption">
                                            <MuiAlert severity={feedbackSeverity} className={classes.feedback}>
                                                <FormattedMessage id={feedback} values={{
                                                    nftSymbol: contractData.nftSymbol,
                                                    nftName: contractData.nftName,
                                                }}/>
                                            </MuiAlert>
                                        </Typography>


                                        <Slider
                                            aria-label="Mint Size"
                                            defaultValue={1}
                                            getAriaValueText={minterValueText}
                                            valueLabelDisplay="auto"
                                            step={1}
                                            marks={minterMarks}
                                            min={1}
                                            max={10}
                                            onChange={(e, val) => setMintAmount(val)}
                                            className={classes.slider}
                                        />

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={buttonClassnameMint}
                                            disabled={loadingMint}
                                            type="submit"
                                            disableElevation
                                            onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTs();
                                                getSmartContractData();
                                            }}
                                        >
                                            <FormattedMessage id="MINTER.MINT.BUTTON"/>
                                        </Button>
                                    </CardContent>
                                </Card>
                            )}
                        </>
                    )}
                </>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    type="submit"
                    disableElevation
                    onClick={(e) => {
                        e.preventDefault();
                        connectBlockchainWrapper();

                    }}
                >
                    <FormattedMessage id="MINTER.CONNECT.BUTTON"/>
                </Button>
            )}
        </div>
    )
}

export default injectIntl(
    connect(
        null,
        null
    )(Minter)
);