import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Grid, Table, TableBody, TableCell, TableContainer,
    TableHead, TablePagination,
    TableRow, TableSortLabel
} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";

import {getUsers} from "../../../../../api/users";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faUserEdit} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        button: {
            //background: theme.palette.neon.main,
            margin: theme.spacing(1),
        },
        iconSize: {
            fontSize: "5px"
        },
        tableButton: {
            background: theme.palette.neon.main,
            minWidth: 0,
            paddingRight: '5px'
        },
        badgeRole: {
            background: theme.palette.error.main,
            height: '20px',
            padding: '0 6px',
            zIndex: 1,
            flexWrap: 'wrap',
            fontSize:' 0.75rem',
            minWidth: '20px',
            boxSizing: 'border-box',
            transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            alignItems: 'center',
            fontFamily: 'Poppins',
            fontWeight: 500,
            lineHeight: 1,
            alignContent: 'center',
            borderRadius: '10px',
            flexDirection: 'row',
            justifyContent: 'center',
            color: "#fff",
            marginLeft: '5px'
        }
    }),
)

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {id: 'fullName', numeric: false, disablePadding: true, label: <FormattedMessage id="ADMINISTRATION.USERMANAGEMENT.NAME"/>},
    {id: 'email', numeric: true, disablePadding: false, label: <FormattedMessage id="ADMINISTRATION.USERMANAGEMENT.EMAIL"/>},
    {id: 'status', numeric: true, disablePadding: false, label: <FormattedMessage id="ADMINISTRATION.USERMANAGEMENT.STATUS"/>},
    {id: 'ldap', numeric: true, disablePadding: false, label: <FormattedMessage id="ADMINISTRATION.USERMANAGEMENT.TYPE"/>},
    {id: 'action', numeric: true, disablePadding: false, label: ''},
];

function EnhancedTableHead(props) {
    const {classes, order, orderBy, onRequestSort} = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const UserManagementView = ({userList}) => {
    const classes = useStyles();
    const [rows] = React.useState(userList);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <Card className={classes.root}>
            <CardHeader
                className={classes.header}
                title={<FormattedMessage id="ADMINISTRATION.USERMANAGEMENT.TITLE"/>}
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<FontAwesomeIcon icon={faPlus}/>}
                        component={Link}
                        to={'/administration/users/create'}
                        disableElevation
                    >
                        <FormattedMessage id="ADMINISTRATION.USERMANAGEMENT.CREATE"/>
                    </Button>
                }
            />
            <CardContent>
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {

                                                return (
                                                    <TableRow hover tabIndex={-1} key={row.email}>
                                                        <TableCell padding="none">
                                                            {row.fullName}
                                                            {row.role === 'admin' && (
                                                                <span className={classes.badgeRole}>{row.role}</span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">{row.email}</TableCell>
                                                        <TableCell align="right">{row.status}</TableCell>
                                                        <TableCell align="right">
                                                            {row.ldap && (
                                                                <>LDAP</>
                                                            )}
                                                            {!!!row.ldap && (
                                                                <>Local</>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Button className={classes.tableButton} component={Link}
                                                                    to={'/administration/users/' + row.id + '/edit'}>
                                                                <FontAwesomeIcon icon={faUserEdit}/>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />

                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    );
};

export default class AdminUserManagement extends React.Component {
    constructor() {
        super();
        this.state = {
            users: null
        }
    }

    componentDidMount() {
        getUsers().then(res => {
            const data = res.data;
            this.setState({users: data});
        })
    };

    render() {
        return (
            <>
                {this.state.users === null &&
                <div style={{margin: '0 auto'}}>
                    <CircularProgress/>
                </div>
                }
                {this.state.users !== null &&
                <UserManagementView userList={this.state.users}/>
                }
            </>
        );
    }
}