import React from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, CardHeader, CardMedia, Grid, Paper, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import * as auth from "../../../../../store/reducers/auth";
import Button from "@material-ui/core/Button";
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.quaternary.main,
        width: '100vw',
        padding: '65px',
        display: 'flex',
        WebkitBoxAlign: "center",
        alignItems: "center",
        WebkitBoxPack: "justify",
        justifyContent: "center"
    },
    grid: {
        maxWidth: '1024px'
    },
    gridRight: {
        display: "inline-block",
        textAlign: "right"
    },
    gridLeft: {
        display: "inline-block"
    },
    gridSpace: {
        marginTop: '60px',
        marginBottom: '30px',
        WebkitBoxAlign: "center",
        alignItems: "center",
        WebkitBoxPack: "justify",
        justifyContent: "center"
    },
    tagBox: {
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingBottom: '12px'
    },
    tagText: {
        color: '#fff',
        fontWeight: "600",
        fontSize: "1.2em",
        backgroundColor: theme.palette.secondary.main,
        display: "inline-flex",
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px',
        textTransform: "uppercase"
    },
    titleBox: {
        paddingLeft: '12px',
        paddingRight: '12px',
    },
    titleText: {
        color: '#fff',
        fontWeight: "600",
        fontSize: "2em",
        backgroundColor: theme.palette.quinary.main,
        display: "inline-flex",
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px'
    },
    textBoxLeft: {
        marginLeft: '12px',
        paddingLeft: '36px',
        paddingRight: '36px',
        borderLeft: "3px solid #000",
        whiteSpace: "pre-line"
    },
    textBoxRight: {
        marginRight: '12px',
        paddingLeft: '36px',
        paddingRight: '36px',
        borderRight: "3px solid #000",
        whiteSpace: "pre-line"
    },
    filler: {
        backgroundImage: 'url(/images/es_trenner.png)',
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        height: '50px',
        width: '100px',
        marginLeft: '-100px'
    },
    iconSocial: {
        marginRight: '12px',
    },
    buttonSocial: {
        marginTop: '32px',
        margin:'12px'
    },
    prices: {
        color: '#fff',
        fontWeight: "400",
        fontSize: "1.6em",
        backgroundColor: theme.palette.secondary.main,
        display: "inline-flex",
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px',
        marginTop: '12px',
        minWidth: '330px'
    },
    pricesBox:{
        marginTop: '48px'
    },
    pricesOuterBox:{
        marginTop: '24px',
        WebkitBoxAlign: "center",
        alignItems: "center",
        WebkitBoxPack: "justify",
        justifyContent: "center"
    }
}));

const Content = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={5} className={classes.grid}>
                <Grid item xs={12}>

                    <Grid container spacing={5} className={classes.gridLeft}>
                        <Grid item xs={12}>
                            <div className={classes.tagBox}>
                                <Typography className={classes.tagText}>
                                    <FormattedMessage id="CONTENT.ABOUT.TAG"/>
                                </Typography>
                            </div>
                            <div className={classes.titleBox}>
                                <Typography className={classes.titleText}>
                                    <FormattedMessage id="CONTENT.ABOUT.TITLE"/>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.textBoxLeft}>
                                <Typography>
                                    <FormattedMessage id="CONTENT.ABOUT.TEXT"/>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} className={classes.gridSpace}>
                        <div className={classes.filler}>

                        </div>

                    </Grid>

                    <Grid container spacing={5} className={classes.gridRight}>
                        <Grid item xs={12}>
                            <div className={classes.tagBox}>
                                <Typography className={classes.tagText}>
                                    <FormattedMessage id="CONTENT.FUNDING.TAG"/>
                                </Typography>
                            </div>
                            <div className={classes.titleBox}>
                                <Typography className={classes.titleText}>
                                    <FormattedMessage id="CONTENT.FUNDING.TITLE"/>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.textBoxRight}>
                                <Typography>
                                    <FormattedMessage id="CONTENT.FUNDING.TEXT"/>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} className={classes.gridSpace}>
                        <div className={classes.filler}>

                        </div>
                    </Grid>

                    <Grid container spacing={5} className={classes.gridLeft}>
                        <Grid item xs={12}>
                            <div className={classes.tagBox}>
                                <Typography className={classes.tagText}>
                                    <FormattedMessage id="CONTENT.BENEFIT.TAG"/>
                                </Typography>
                            </div>
                            <div className={classes.titleBox}>
                                <Typography className={classes.titleText}>
                                    <FormattedMessage id="CONTENT.BENEFIT.TITLE"/>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.textBoxLeft}>
                                <Typography>
                                    <FormattedMessage id="CONTENT.BENEFIT.TEXT"/>
                                </Typography>
                                <Grid container className={classes.pricesOuterBox}>
                                    <Grid item xs={6} className={classes.pricesBox}>
                                        <div className={classes.prices}>
                                            1st place gets 5000 FTM
                                        </div>
                                        <div className={classes.prices}>
                                            2nd place gets 3750 FTM
                                        </div>
                                        <div className={classes.prices}>
                                            3rd place gets 2500 FTM
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} className={classes.gridSpace}>
                        <div className={classes.filler}>

                        </div>
                    </Grid>

                    <Grid container spacing={5} className={classes.gridRight}>
                        <Grid item xs={12}>
                            <div className={classes.tagBox}>
                                <Typography className={classes.tagText}>
                                    <FormattedMessage id="CONTENT.SOCIALMEDIA.TAG"/>
                                </Typography>
                            </div>
                            <div className={classes.titleBox}>
                                <Typography className={classes.titleText}>
                                    <FormattedMessage id="CONTENT.SOCIALMEDIA.TITLE"/>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.textBoxRight}>
                                <Typography>
                                    <FormattedMessage id="CONTENT.SOCIALMEDIA.TEXT"/>
                                </Typography>

                                <Button variant="contained"
                                        color="primary"
                                        type="submit"
                                        disableElevation
                                        className={classes.buttonSocial}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href='https://discord.gg/fk2udfAF5D';
                                        }}>
                                    <FontAwesomeIcon size='lg' icon={faDiscord} className={classes.iconSocial}/> Visit Discord
                                </Button>
                                <Button variant="contained"
                                        color="primary"
                                        type="submit"
                                        disableElevation
                                        className={classes.buttonSocial}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href='https://twitter.com/edensanctuaryio';
                                        }}>
                                    <FontAwesomeIcon size='lg' icon={faTwitter} className={classes.iconSocial}/> Visit Twitter
                                </Button>
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    )
}

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Content)
);