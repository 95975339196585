import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt, faUserCog} from "@fortawesome/free-solid-svg-icons";
import {Divider} from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        paddingTop: '10px',
        paddingBottom: '6px'
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    button: {
        textTransform: 'none'
    },
    icon: {
        minWidth: '20px',
        maxWidth: '20px',
        marginRight: '10px',
        fontSize: '0.8rem',
        color: theme.palette.neon.main,
    }
}));

function UserMenu(props) {
    const classes = useStyles();
    const { user } = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.button}
                >
                    <FormattedMessage id="MENU.USER.WELCOME" /> {user.firstName}
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={handleClose} component={Link} to='/profile/edit'>
                                            <FontAwesomeIcon icon={faUserCog} className={classes.icon}/>
                                            <span>Settings</span>
                                        </MenuItem>
                                        <MenuItem onClick={handleClose} component={Link} to='/logout'>
                                            <FontAwesomeIcon icon={faSignOutAlt} className={classes.icon}/>
                                            <span>Logout</span>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(
    mapStateToProps
)(UserMenu);
