import React, {Component} from "react";
import {connect, shallowEqual, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {SplashScreen} from "../../../layout";
import * as auth from '../../../../store/reducers/auth';

class LogoutPage extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? <SplashScreen /> : <Redirect to="/auth" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(LogoutPage);

