import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';

import * as auth from './auth';
import * as i18n from './i18n';
import * as snackbar from './snackbar';
import * as blockchain from "./blockchain";
import * as contract from './contract'

export const createRootReducer = combineReducers({
  auth: auth.reducer,
  i18n: i18n.reducer,
  snackbar: snackbar.reducer,
  blockchain: blockchain.reducer,
  contract: contract.reducer
});

export function* createRootSaga() {
  yield all([auth.saga()]);
}
