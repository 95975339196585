import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";


const useStyles = makeStyles(theme => ({
    title: {
        textTransform: 'uppercase',
        fontSize: '0.6rem',
        paddingLeft: '30px',
        paddingTop: '28px',
        paddingBottom: '10px'
    }
}));

export const MenuTitleComponent = props => {
    const { title } = props
    const classes = useStyles()

    return (
        <div className={classes.title}><FormattedMessage id={title} /></div>
    )
}

