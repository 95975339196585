import axios from 'axios';

// get react app api url if hosted somewhere else
const { REACT_APP_API_URL } = process.env;

export const AUTH_LOGIN_URL = '/api/authentication/login/password';
export const AUTH_REGISTER_URL = '/api/authentication/register';
export const AUTH_REQUEST_PASSWORD_URL = '/api/authentication/forgot-password';

export function login(username, password) {
  return axios.post(AUTH_LOGIN_URL, { username, password });
}

export function register(email, fullname, username, password) {
  return axios.post(AUTH_REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(AUTH_REQUEST_PASSWORD_URL, { email });
}
