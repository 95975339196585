import React from "react";
import {createTheme, ThemeProvider} from "@material-ui/core";

const theme = createTheme(
    {
        typography: {
            fontFamily: ["Poppins"].join(",")
        },
        palette: {
            primary: {
                main: "#3A6B35",
            },
            secondary: {
                main: "#E3B448",
            },
            tertiary: {
              main: "#CBD18F",
            },
            quaternary: {
              main: "#fff",
            },
            quinary: {
                main: '#201E20'
            },
            error: {
                main: "#e63946",
            },
            neon: {
                main: "#FF0099"
            }
        },
        props: {
            MuiButtonBase: {
                disableRipple: true
            },
            MuiPopover: {
                elevation: 1
            }
        }
    }
);

export function MaterialTheme(props) {
    const {children} = props;

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
