import React from 'react'
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from 'prop-types'

import {makeStyles} from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'

import {MenuItemComponent} from '../MenuItemComponent'


const useStyles = makeStyles(theme => ({
    menuItem: {
        '&.active': {
            color: theme.palette.quaternary.main,
            '& .MuiListItemIcon-root': {
                color: '#fff',
            },
        },
    },
    menuItemIcon: {
        color: '#97c05c',
    },
    menuLink: {
        fontSize: '0.7rem',
        paddingLeft: '10px'
    },
    bullet: {
        width: '4px',
        height: '4px',
        background: theme.palette.neon.main,
        borderRadius: '100%',
        marginLeft: '40px'
    }
}));

export const AppMenuItemPropTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    items: PropTypes.array,
    permissions: PropTypes.array
}

function Three(props) {
    const {name, link, items = [], permissions, user} = props
    const classes = useStyles()
    const isExpandable = items && items.length > 0
    const hasPermission = permissions.includes(user.role)
    const [open, setOpen] = React.useState(false)

    function handleClick() {
        setOpen(!open)
    }

    const MenuItemRoot = (
        <>
            {!!hasPermission && (
                <MenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
                    <span className={classes.bullet}></span>
                    <ListItemText disableTypography primary={<FormattedMessage id={name}/>}
                                  className={classes.menuLink}/>
                    {isExpandable && !open && <IconExpandMore/>}
                    {isExpandable && open && <IconExpandLess/>}
                </MenuItemComponent>
            )}
        </>
    )

    return (
        <>
            {MenuItemRoot}
        </>
    )
}

Three.propTypes = AppMenuItemPropTypes

const mapStateToProps = ({auth: {user}}) => ({user});

export default connect(
    mapStateToProps
)(Three);