import React, {Suspense, lazy} from 'react';
import {Redirect, Switch, Route} from 'react-router-dom';
import {SplashScreen, ContentRoute, Construct} from '../../layout';
import {DashboardPage} from '../../modules/dashboard';

// Authentication verify
import {connect, shallowEqual, useSelector} from 'react-redux';
import * as auth from '../../../store/reducers/auth';

// Authentication RBAC
import {CredentialProvider} from 'react-rbac-guard';
import {
    GuardAdmin,
    GuardGuest,
    GuardManager,
    GuardUser,
    NeedAuthorized,
    NeedExtendedAuthorization
} from '../../helpers';

// Route files
import Administration from "../../modules/administration";
import ErrorsPage from "../../modules/errors";
import Profile from "../../modules/profile";
import {LandingPage} from "../../modules/minter";
import {VerificationPage} from "../../modules/verification";


function Main(props) {
    // Verify if user logged in
    props.verify();

    const {userRole} = useSelector(
        ({auth}) => ({
            userRole: auth.role || 'guest'
        }),
        shallowEqual
    );

    return (
        <Suspense fallback={<SplashScreen/>}>
            <CredentialProvider value={userRole}>
                <GuardUser>
                    <Switch>
                        <Redirect exact from="/" to="/dashboard"/>

                        <Route path="/dashboard" component={DashboardPage}/>

                        <Route path="/profile" component={Profile}/>

                        <Route path="/administration" component={Administration}/>

                        <Route path="*" component={ErrorsPage}/>
                    </Switch>
                </GuardUser>
            </CredentialProvider>
        </Suspense>
    );
}

export default connect(
    null,
    auth.actions
)(Main);

