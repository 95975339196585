import React from 'react'
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        backgroundImage: "url(/images/banner_background.jpeg)",
        backgroundSize: "cover",
        height: '300px',
        width: '100vw',
        display: 'flex',
        WebkitBoxAlign: "center",
        alignItems: "center",
        WebkitBoxPack: "justify",
        justifyContent: "center"
    },
    logo: {
        marginTop: '80px',
        height: '280px',
        width: '674px',
        backgroundImage: 'url(/images/logo_eden_sanctuary.png)',
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
    }
}));

export const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.logo}>

            </div>
        </div>
    )
}
