import React from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {actions} from "../../../../../store/reducers/i18n";
import {ListItemIcon} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDiscord, faTwitter} from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme) => ({
    listItemIcon: {
        minWidth: 0,
        color: theme.palette.secondary.main
    },
    itemIcon: {
        borderRadius: '10px'
    },
    selectItemIcon: {
        borderRadius: '10px',
        marginRight: '7px'
    },
    listSocial: {
        float: "left"
    }
}));

const socials = [
    {
        short: 'discord',
        name: 'Discord',
        icon: faDiscord,
        link: 'https://discord.gg/fk2udfAF5D'
    },
    {
        short: 'twitter',
        name: 'Twitter',
        icon: faTwitter,
        link: 'https://twitter.com/edensanctuaryio'
    }
];

function SocialMediaMenu(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {socials.map((social) => (
            <List component="nav" className={classes.listSocial} key={social.short}>
                <ListItem
                    button
                    component={Link}
                    aria-haspopup="true"
                    href={social.link}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <FontAwesomeIcon size='lg' icon={social.icon} className={classes.listItemIcon}/>
                    </ListItemIcon>
                </ListItem>
            </List>
            ))}
        </div>
    );
}

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang });

export default connect(
    mapStateToProps,
    actions
)(SocialMediaMenu);