import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
    request: "CONNECTION_REQUEST",
    success: "CONNECTION_SUCCESS",
    failed: "CONNECTION_FAILED",
    update: "UPDATE_ACCOUNT"
};

const initialState = {
    loading: false,
    account: null,
    smartContract: null,
    web3: null,
    errorMsg: "",
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.request:
            return {
                ...initialState,
                loading: true,
            };
        case actionTypes.success:
            return {
                ...state,
                loading: false,
                account: action.payload.account,
                smartContract: action.payload.smartContract,
                web3: action.payload.web3,
            };
        case actionTypes.failed:
            return {
                ...initialState,
                loading: false
            };
        case actionTypes.update:
            return {
                ...state,
                account: action.payload.account,
            };
        default:
            return state;
    }
};

export const actions = {
    connectRequest: message => ({ type: actionTypes.request }),
    connectSuccess: (payload) => ({
       type: actionTypes.success,
       payload: payload
    }),
    connectFailed: (payload) => ({
       type: actionTypes.failed,
       payload: payload
    }),
    updateAccountRequest: (payload) => ({
        type: actionTypes.update,
        payload: payload
    })
};