import * as blockchain from "../store/reducers/blockchain";
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import networks from "../config/networks.json"
import store from "../store/store";
import axios from "axios";

const getConfiguredNetwork = async() => {
    for(let network of networks) {
        if(network.id === process.env.BLOCKCHAIN_NETWORK) {
            return network
        }
    }
    return undefined
}

const getContractAbi = async() => {
    let network = await getConfiguredNetwork()

    let config = {
        method: 'get',
        url: network.abiBaseUrl + process.env.BLOCKCHAIN_CONTRACT_AT + '&apikey=' + process.env.BLOCKCHAIN_RPC_API_KEY,
    };

    let result = await axios(config)
        .then((response) => {
            return JSON.parse(response.data.result)
        })
        .catch((error) => {
            return undefined
        });

    return result
}

export const connectBlockchain = async () => {
    store.dispatch(blockchain.actions.connectRequest());

    let result = {
        id: '',
        network: '',
        type: '',
        success: false
    }

    let network = await getConfiguredNetwork()

    const {ethereum} = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;

    if (metamaskIsInstalled) {
        Web3EthContract.setProvider(ethereum);
        let web3 = new Web3(ethereum);
        try {
            const accounts = await ethereum.request({
                method: "eth_requestAccounts",
            });
            const networkId = await ethereum.request({
                method: "net_version",
            });

            if (networkId === network.id.toString()) {
                const SmartContractObj = new Web3EthContract(
                    await getContractAbi(),
                    process.env.BLOCKCHAIN_CONTRACT_AT
                );

                store.dispatch(
                    blockchain.actions.connectSuccess({
                        account: accounts[0],
                        smartContract: SmartContractObj,
                        web3: web3,
                    })
                );

                ethereum.on("accountsChanged", (accounts) => {
                    dispatch(blockchain.actions.update(accounts[0]));
                });

                ethereum.on("chainChanged", () => {
                    window.location.reload();
                });

                result.success = true

                return result

            } else {
                store.dispatch(blockchain.actions.connectFailed());

                result.id = 'BLOCKCHAIN.CHANGE.NETWORK'
                result.network = network.name
                result.type = 'error'
                result.success = false

                return result
            }
        } catch (err) {
            console.log(err)
            store.dispatch(blockchain.actions.connectFailed());

            result.id = 'BLOCKCHAIN.GENERAL.ERROR'
            result.type = 'error'
            result.success = false

            return result
        }
    } else {
        store.dispatch(blockchain.actions.connectFailed());

        result.id = 'BLOCKCHAIN.INSTALL.METAMASK'
        result.type = 'error'
        result.success = false

        return result
    }

}