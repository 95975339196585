import React, {Suspense, lazy} from 'react';
import {Redirect, Switch, Route} from 'react-router-dom';
import {SplashScreen} from '../../layout';

// Authentication verify
import {connect, shallowEqual, useSelector} from 'react-redux';

// Authentication RBAC
import {CredentialProvider} from 'react-rbac-guard';
import {
    GuardGuest,
} from '../../helpers';

// Route files
import {LandingPage} from "../../modules/minter";
import {VerificationPage} from "../../modules/verification";


function MainGuests(props) {

    const {userRole} = useSelector(
        ({auth}) => ({
            userRole: auth.role || 'guest'
        }),
        shallowEqual
    );

    return (
        <Suspense fallback={<SplashScreen/>}>
            <CredentialProvider value={userRole}>
                <GuardGuest>
                    <Switch>
                        <Redirect exact from="/" to="/mint"/>

                        <Route path="/mint" component={LandingPage}/>

                        <Route path="/verification" component={VerificationPage}/>

                        <Redirect exact from="*" to="/mint"/>
                    </Switch>
                </GuardGuest>
            </CredentialProvider>
        </Suspense>
    );
}

export default connect(
    null,
    null
)(MainGuests);

