import React from 'react';
import {Card, CardContent, CardHeader, Container, Grid, Paper, Typography} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Header} from "./components/Header";
import Minter from "./components/Minter";
import Content from "./components/Content";
import {AngryDeer} from "./components/AngryDeer"
import * as auth from "../../../../store/reducers/auth";
import Footer from "./components/Footer";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            flexGrow: 1,
        }
    }),
)

function MinterPage() {
    const classes = useStyles();

    return(
        <>
            <Header />
            <Minter />
            <AngryDeer />
            <Content />
            <Footer/>
        </>
    );
}

export default injectIntl(
    connect(
        null,
        auth.actions
    )(MinterPage)
);
