import React from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import * as auth from "../../../../../store/reducers/auth";
import {Grid, ListItem, ListItemText, Typography} from "@material-ui/core";
import List from "@material-ui/core/List";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.quinary.main,
        backgroundImage: 'url(/images/footer_deer.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        width: '100vw',
        padding: '65px',
        display: 'flex',
        WebkitBoxAlign: "center",
        alignItems: "center",
        WebkitBoxPack: "justify",
        justifyContent: "center"
    },
    grid: {
        maxWidth: '1024px',
        color: theme.palette.quaternary.main
    },
    header: {
        fontWeight: "bolder",
        paddingBottom: '12px'
    },
    list: {
        padding: 0
    },
    listItem: {
        padding: 0
    }
}));

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={5} className={classes.grid}>
                <Grid item xs={4}>
                    <Typography className={classes.header}>
                        Eden Sanctuary
                    </Typography>

                        <List className={classes.list}>
                            <ListItem button component="a" className={classes.listItem} href="https://ftmscan.com/address/0x312dc1cd8c350bcb7991375416649f90185cc1d3#code">
                                <ListItemText primary="Contract Verification" />
                            </ListItem>
                            <ListItem button component="a" className={classes.listItem} href="https://fantom.foundation/">
                                <ListItemText primary="Fantom Foundation" />
                            </ListItem>
                        </List>

                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.header}>
                        Social Media & Marketplace
                    </Typography>
                    <List className={classes.list}>
                        <ListItem button component="a" className={classes.listItem} href="https://twitter.com/edensanctuaryio">
                            <ListItemText primary="Twitter" />
                        </ListItem>
                        <ListItem button component="a" className={classes.listItem} href="https://discord.gg/fk2udfAF5D">
                            <ListItemText primary="Discord" />
                        </ListItem>
                        <ListItem button component="a" className={classes.listItem} href="https://paintswap.finance/marketplace/collections/0x312dc1cd8c350bcb7991375416649f90185cc1d3?cache=no">
                            <ListItemText primary="PaintSwap" />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.header}>
                        Contact
                    </Typography>
                    <List className={classes.list}>
                        <ListItem button component="a" className={classes.listItem} href="https://twitter.com/edensanctuaryio">
                            <ListItemText primary="Twitter DM" />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </div>
    )
}

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Footer)
);