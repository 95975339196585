import React from 'react'
import {makeStyles, createStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'

import {MenuHeader} from './components/MenuHeader'
import One from './components/tiers/One'

import {faCogs, faLayerGroup, faPenFancy, faUsers, faUsersCog} from '@fortawesome/free-solid-svg-icons'
import {faObjectGroup} from "@fortawesome/free-regular-svg-icons";

const appMenuItems = [
    {
        name: 'NAV.T1.DASHBOARD',
        link: '/dashboard',
        Icon: faLayerGroup,
        title: 'NAV.TITLE.HOME',
        permissions: ['user', 'admin']
    },
    {
        name: 'NAV.T1.NESTEDPAGES',
        Icon: faObjectGroup,
        title: 'NAV.TITLE.SAMPLE',
        permissions: ['user', 'admin'],
        items: [
            {
                name: 'NAV.T2.LEVEL',
                permissions: ['admin']
            },
            {
                name: 'NAV.T2.LEVEL',
                permissions: ['user', 'admin'],
                items: [
                    {
                        name: 'NAV.T3.LEVEL',
                        permissions: ['user', 'admin']
                    },
                    {
                        name: 'NAV.T3.LEVEL',
                        permissions: ['admin']
                    },
                ],
            },
        ],
    },
    {
        name: 'NAV.T1.ADMINISTRATION.SETTINGS',
        link: '/administration/settings',
        Icon: faCogs,
        title: 'NAV.TITLE.ADMINISTRATION',
        permissions: ['admin']
    },
    {
        name: 'NAV.T1.ADMINISTRATION.USERS',
        link: '/administration/users',
        Icon: faUsersCog,
        permissions: ['admin']
    },
]

const useStyles = makeStyles(theme =>
    createStyles({
        appMenu: {
            width: '100%',
        }
    }),
)

export const Navbar = props => {
    const {handler} = props;
    const classes = useStyles();

    return (
        <>
            <MenuHeader handler={handler}/>
            <List component="nav" className={classes.appMenu} disablePadding>
                {appMenuItems.map((item, index) => (
                    <One {...item} key={index}/>
                ))}
            </List>
        </>
    )
}