import React from 'react'
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    wrapper: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
        background: '#0e1a29',
        height: theme.spacing(8)
    },
    logo: {
        float: 'left',
        paddingLeft: '30px',
        paddingTop: '5px'
    }
}));

export const MenuHeader = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.logo}>
                <img src={'/icons/logo.svg'} height={40} />
            </div>
        </div>
    )
}

