import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as snackbar from '../../store/reducers/snackbar'

import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from "prop-types";
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertWrapper(props) {
    const { message, severity } = props;
    const dispatch = useDispatch();

    function handleClose() {
        dispatch(snackbar.actions.clear());
    }

    return (
        <Alert onClose={handleClose} severity={severity}>
            {message}
        </Alert>
    );
}

AlertWrapper.propTypes = {
    message: PropTypes.node,
    onClose: PropTypes.func,
    severity: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
};

export function MaterialUiSnackbar() {

    const dispatch = useDispatch();;

    const message = useSelector(({snackbar}) => snackbar.message);
    const visible = useSelector(({snackbar}) => snackbar.visible);
    const type = useSelector(({snackbar}) => snackbar.type);

    function handleClose() {
        dispatch(snackbar.actions.clear());
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={visible}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <AlertWrapper
                severity={type}
                message={message}
            />
        </Snackbar>
    );
}
