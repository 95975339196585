import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import LanguageSelectMenu from "./components/LanguageSelectMenu";
import UserMenu from "./components/UserMenu";
import WalletMenu from "./components/WalletMenu";
import SocialMediaMenu from "./components/SocialMediaMenu";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 0
    }
}));

export const Header = () => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                {/*<UserMenu />*/}
                <SocialMediaMenu />
                <LanguageSelectMenu />
            </div>
        </>
    )
}

