import React from 'react';
import {Redirect, Switch, Route} from 'react-router-dom';
import {shallowEqual, useSelector} from 'react-redux';
import {Construct} from '../../layout';
import Main from '../Main';
import MainGuests from "../Main/MainGuests";
import {LogoutPage, AuthenticationPage} from '../../modules/authentication';
import ErrorsPage from '../../modules/errors';

// Authentication
import {CredentialProvider} from 'react-rbac-guard';
import {GuardNotGuest, GuardGuest} from '../../helpers'

export default function Routes() {

    const {userRole} = useSelector(
        ({auth}) => ({
            userRole: auth.role || 'guest'
        }),
        shallowEqual
    );

    return (
        <CredentialProvider value={userRole}>
            <GuardGuest>
                <Switch>
                    <Construct>
                        <MainGuests/>
                    </Construct>

                    <Redirect to="/"/>
                </Switch>
            </GuardGuest>
            {/*<GuardNotGuest>
                <Switch>
                    <Redirect from="/auth" to="/"/>

                    <Route path="/error" component={ErrorsPage}/>
                    <Route path="/logout" component={LogoutPage}/>
                    <Construct>
                        <Main/>
                    </Construct>
                </Switch>
            </GuardNotGuest>*/}
        </CredentialProvider>
    );
}
