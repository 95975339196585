import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {GuardGuest, GuardUser} from "../../helpers";
import {CredentialProvider} from "react-rbac-guard";
import {ContentRoute} from "../../layout";
import ErrorsPage from "../errors";
import ProfileEdit from "./pages/ProfileEdit";


function Profile(props) {
    const {user} = props;

    return(
        <CredentialProvider value={user.role}>
            <GuardUser>
                <Switch>
                    {/* User management routes*/}
                    <ContentRoute exact path="/profile/edit" component={ProfileEdit}/>}
                    <Route path="*" component={ErrorsPage} />
                </Switch>
            </GuardUser>
            <GuardGuest>
                <Redirect to="/error/407" />
            </GuardGuest>
        </CredentialProvider>
    );
}

const mapStateToProps = ({auth: {user}}) => ({user});

export default connect(
    mapStateToProps
)(Profile);
