import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl";

import {makeStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {MenuItemComponent} from '../MenuItemComponent'
import {MenuTitleComponent} from '../MenuTitleComponent'
import Two from './Two';

// Authentication verify
import {connect, shallowEqual, useSelector} from 'react-redux';
import * as auth from '../../../../../../store/reducers/auth';


const useStyles = makeStyles(theme => ({
    menuItem: {
        '&.active': {
            color: theme.palette.quaternary.main,
            '& .MuiListItemIcon-root': {
                color: '#fff',
            },
        },
        '&:hover': {
            color: theme.palette.quaternary.main
        },
        paddingLeft: '30px'
    },
    menuItemIcon: {
        color: theme.palette.neon.main,
        minWidth: '25px',
        animation: 'neon .08s ease-in-out infinite alternate'
    },
    menuLink: {
        fontSize: '0.8rem',
        paddingLeft: '10px'
    },
    icon: {
        fontSize: '1rem',
    },
    expand: {
        fontSize: '1rem'
    }
}));

export const AppMenuItemPropTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    Icon: PropTypes.object,
    items: PropTypes.array,
    title: PropTypes.string,
    permissions: PropTypes.array
}

function One(props) {
    const {name, link, Icon, items = [], title, permissions, user} = props
    const classes = useStyles()
    const isExpandable = items && items.length > 0
    //const hasPermission = permissions.includes(user.role)
    const [open, setOpen] = React.useState(false)

    const {hasPermission} = useSelector(
        ({auth}) => ({
            hasPermission: permissions.includes(auth.role) || permissions.includes('guest')
        }),
        shallowEqual
    );

    function handleClick() {
        setOpen(!open)
    }

    const MenuItemRoot = (
        <>
            {!!hasPermission && (
                <div>
                    {title && (
                        <MenuTitleComponent title={title}/>
                    )}
                    <MenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
                        {!!Icon && (
                            <ListItemIcon className={classes.menuItemIcon}>
                                <FontAwesomeIcon icon={Icon}/>
                            </ListItemIcon>
                        )}
                        <ListItemText disableTypography primary={<FormattedMessage id={name}/>} inset={!Icon}
                                      className={classes.menuLink}/>
                        {isExpandable && !open && <IconExpandMore className={classes.expand}/>}
                        {isExpandable && open && <IconExpandLess className={classes.expand}/>}
                    </MenuItemComponent>
                </div>
            )}
        </>
    )

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider/>
            <List component="div" disablePadding>
                {items.map((item, index) => (
                    <Two {...item} key={index}/>
                ))}
            </List>
        </Collapse>
    ) : null

    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    )
}

One.propTypes = AppMenuItemPropTypes

const mapStateToProps = ({auth: {user}}) => ({user});

export default connect(
    mapStateToProps,
    auth.actions
)(One);