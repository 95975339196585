import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import {Navbar} from './components/navbar'
import {Header} from "./components/header";

import {AppBar, Hidden, IconButton, Toolbar} from "@material-ui/core"

import MenuIcon from '@material-ui/icons/Menu';
import WalletMenu from "./components/header/components/WalletMenu";


const drawerWidth = 0

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: '0px !important;;',
        margin: '0px !important;;',
        minHeight: '100vh',
        maxHeight: '100%',
        width: '100vw'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        paddingBottom: theme.spacing(4),
        background: theme.palette.primary.dark,
        color: '#8d99ae',
    },
    content: {
        flexGrow: 1,
        display: 'flex'
    },
    container: {
        maxWidth: '100vw',
        //padding: '0px !important;;',
        margin: '0px !important;;',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing(8),
        //paddingBottom: theme.spacing(4),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        color: theme.palette.neon.main
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        backgroundColor: '#201E20'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
            background: theme.palette.primary.dark,
        },
    }
}))

export function Construct(props) {
    const { window, children } = props;
    const classes = useStyles()
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        {/*<IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon/>
                        </IconButton>*/}
                        {/*<Typography noWrap>
                            Responsive drawer
                        </Typography>*/}
                        <Header />
                    </Toolbar>

                    <WalletMenu />

                </AppBar>
                {/*
                <nav className={classes.drawer}>
                    <Hidden smUp implementation="js">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true,
                            }}
                        >
                            <Navbar />
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            container={container}
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            open
                        >
                            <Navbar />
                        </Drawer>
                    </Hidden>
                </nav>*/}

                <main className={classes.content}>
                    {/*begin::Main*/}
                    <Container className={classes.container}>
                        {children}
                    </Container>
                    {/*end::Main*/}
                </main>
            </div>
            {/*<Header/>*/}
        </>
    );
}

Construct.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
    children: PropTypes.object
};