import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import MediaQuery from 'react-responsive';
import {Card, CardContent, CardHeader, CardMedia, Grid, Typography} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import {FormattedMessage} from "react-intl";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        width: '100vw',
        display: 'flex',
    },
    rootMobile: {
        backgroundColor: theme.palette.primary.light,
        width: '100vw',
        display: 'flex',
    },
    deerLogo: {
        backgroundImage: 'url(/images/angry_deer_collection_logo_2.png)',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left bottom',
    },
    card: {
        width: '100%',
        backgroundColor: "transparent",
        border: 0,
        boxShadow: "none",
        paddingRight: '48px'
    },
    cardMobile: {
        width: '100%',
        backgroundColor: "transparent",
        border: 0,
        boxShadow: "none",
        padding: '24px'
    },
    cardTitle: {
        color: '#fff',
        fontWeight: "600",
        fontSize: "2em",
        backgroundColor: theme.palette.secondary.main,
        display: "inline-flex",
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px',
        marginBottom: '12px'
    },
    cardText: {
        whiteSpace: "pre-line"
    },
    mobileHeader: {
        backgroundImage: 'url(/images/angry_deer_collection_logo_l.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
        display: 'flex',
        width: '100%',
        height: '33vw'
    },
    buttonSocial: {
        marginTop: '21px'
    },
    paintswapicon: {
        paddingRight: '12px'
    }
}));

const mobile = `
    @media only screen and (max-width: 1024px) {
        .ondesktop {
            visibility: hidden;
            display: none;
        }
        
        .portlet-mobile {
            box-shadow: none !important;
            background-color: inherit;
        }
    }
    
    @media only screen and (min-width: 1024px) {
        .onmobile {
            visibility: hidden;
            display: none;
        }
    }
`

export const AngryDeer = () => {
    const classes = useStyles();

    return (
        <>
            <style>{mobile}</style>
            <div className="onmobile">
                <div className={classes.rootMobile}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Card className={classes.cardMobile}>
                                <CardContent className={classes.cardContent}>
                                    <Typography component="div" className={classes.cardTitle}>
                                        <FormattedMessage id="CONTENT.COLLECTION.AD.TITLE"/>
                                    </Typography>
                                    <Typography component="div" className={classes.cardText}>
                                        <FormattedMessage id="CONTENT.COLLECTION.AD.TEXT"/>
                                    </Typography>
                                    <Button variant="contained"
                                            color="secondary"
                                            type="submit"
                                            disableElevation
                                            disabled={true}
                                            className={classes.buttonSocial}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href='https://twitter.com/edensanctuaryio';
                                            }}>
                                        <img src={'/images/paintswap_icon.png'} height={'24px'} className={classes.paintswapicon}/>Collection on PaintSwap
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="ondesktop">


                <div className={classes.root}>
                    <Grid container spacing={0}>
                        <Grid item xs={4} className={classes.deerLogo}>

                        </Grid>
                        <Grid item xs={8}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography component="div" className={classes.cardTitle}>
                                        <FormattedMessage id="CONTENT.COLLECTION.AD.TITLE"/>
                                    </Typography>
                                    <Typography component="div" className={classes.cardText}>
                                        <FormattedMessage id="CONTENT.COLLECTION.AD.TEXT"/>
                                    </Typography>
                                    <Button variant="contained"
                                            color="secondary"
                                            type="submit"
                                            disableElevation
                                            className={classes.buttonSocial}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href='https://paintswap.finance/marketplace/collections/0x312dc1cd8c350bcb7991375416649f90185cc1d3?cache=no';
                                            }}>
                                         <img src={'/images/paintswap_icon.png'} height={'24px'} className={classes.paintswapicon}/>Collection on PaintSwap
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}
