import React from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader, CircularProgress,
    FormControl,
    Grid, InputLabel, MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Formik} from "formik";
import * as Yup from "yup";
import * as snackbar from "../../../../store/reducers/snackbar";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect, useDispatch} from "react-redux";
import * as auth from "../../../../store/reducers/auth";
import {green} from "@material-ui/core/colors";
import clsx from "clsx";
import {Redirect} from "react-router-dom";
import {getUserByToken, putUserByToken} from "../../../../api/profile";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        form: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        formControl: {
            margin: theme.spacing(1),
        },
        formSubmit: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
)


function ProfileEditView(props) {
    const {intl} = props.props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [profile] = React.useState(props.profile);

    // Submit Loading
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    // Redirect after successful submit
    const [redirect, setRedirect] = React.useState(false);

    const renderRedirect = () => {
        if (redirect) {
            return <Redirect to='/dashboard'/>
        }
    };

    return (
        <>
            {renderRedirect()}
            <Card className={classes.root}>
                <CardHeader
                    className={classes.header}
                    title={<FormattedMessage id="PROFILE.EDIT.TITLE"/>}
                />
                <CardContent>
                    <div className={classes.root}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>

                                <Formik
                                    initialValues={{
                                        firstName: profile.firstName,
                                        lastName: profile.lastName,
                                        password: '',
                                        verification: '',
                                    }}
                                    validationSchema={Yup.object().shape({
                                        firstName: Yup.string()
                                            .required(<FormattedMessage id="ADMINISTRATION.USERCREATE.FORMIK.REQ"/>),
                                        lastName: Yup.string()
                                            .required(<FormattedMessage id="ADMINISTRATION.USERCREATE.FORMIK.REQ"/>),
                                        password: Yup.string()
                                            .optional()
                                            .matches(
                                                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                                                intl.formatMessage({
                                                    id: "ADMINISTRATION.USERCREATE.FORMIK.PASSWORD"
                                                })
                                            ),
                                        verification: Yup.string()
                                            .optional()
                                            .oneOf([Yup.ref('password'), null], <FormattedMessage
                                                id="ADMINISTRATION.USERCREATE.FORMIK.VERIFICATION"/>)
                                    })}
                                    onSubmit={(values) => {
                                        setSuccess(false);
                                        setLoading(true);

                                        setTimeout(() => {
                                            putUserByToken(values).then(res => {
                                                setSuccess(true);
                                                setLoading(true);

                                                dispatch(snackbar.actions.show("success", intl.formatMessage({
                                                    id: res.data.message
                                                })));

                                                setRedirect(true);
                                            }).catch(error => {
                                                setSuccess(false);
                                                setLoading(false);

                                                dispatch(snackbar.actions.show("error", intl.formatMessage({
                                                    id: error.data.message
                                                })));

                                                if (error.data.multi !== undefined) {
                                                    error.data.multi.forEach(function (addition) {
                                                        dispatch(snackbar.actions.show("warning", intl.formatMessage({
                                                            id: addition.msg
                                                        })));
                                                    })
                                                }
                                            });
                                        }, 1000);
                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                      }) => (
                                        <form className={classes.form} noValidate autoComplete="off"
                                              onSubmit={handleSubmit}>
                                            <TextField
                                                disabled
                                                label={<FormattedMessage id="ADMINISTRATION.USERCREATE.FORM.USERNAME"/>}
                                                name="username"
                                                style={{margin: 8}}
                                                fullWidth
                                                margin="normal"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={profile.username}
                                            />
                                            <TextField
                                                disabled
                                                type="email"
                                                name="email"
                                                label={<FormattedMessage id="ADMINISTRATION.USERCREATE.FORM.EMAIL"/>}
                                                style={{margin: 8}}
                                                fullWidth
                                                margin="normal"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={profile.email}
                                            />
                                            <TextField
                                                required
                                                label={<FormattedMessage
                                                    id="ADMINISTRATION.USERCREATE.FORM.FIRSTNAME"/>}
                                                name="firstName"
                                                fullWidth
                                                style={{margin: 8}}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.firstName}
                                                helperText={((errors.firstName && touched.firstName) && errors.firstName) ||
                                                <FormattedMessage
                                                    id="ADMINISTRATION.USERCREATE.FORM.FIRSTNAME.HELPER"/>}
                                                error={errors.firstName && touched.firstName}
                                            />
                                            <TextField
                                                required
                                                label={<FormattedMessage id="ADMINISTRATION.USERCREATE.FORM.LASTNAME"/>}
                                                name="lastName"
                                                fullWidth
                                                style={{margin: 8}}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.lastName}
                                                helperText={((errors.lastName && touched.lastName) && errors.lastName) ||
                                                <FormattedMessage id="ADMINISTRATION.USERCREATE.FORM.LASTNAME.HELPER"/>}
                                                error={errors.lastName && touched.lastName}
                                            />
                                            <FormControl className={classes.formControl} fullWidth>
                                                <InputLabel><FormattedMessage id="ADMINISTRATION.USERCREATE.FORM.ROLE"/></InputLabel>
                                                <Select
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.role && touched.role}
                                                    fullWidth
                                                    value={profile.role}
                                                    name="role"
                                                    disabled
                                                >
                                                    <MenuItem value="user">User</MenuItem>
                                                    <MenuItem value="admin">Admin</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                label={<FormattedMessage id="ADMINISTRATION.USERCREATE.FORM.PASSWORD"/>}
                                                name="password"
                                                type="password"
                                                fullWidth
                                                style={{margin: 8}}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                helperText={((errors.password && touched.password) && errors.password) ||
                                                <FormattedMessage id="ADMINISTRATION.USEREDIT.FORM.PASSWORD.HELPER"/>}
                                                error={errors.password && touched.password}
                                            />
                                            <TextField
                                                label={<FormattedMessage
                                                    id="ADMINISTRATION.USERCREATE.FORM.VERIFICATION"/>}
                                                name="verification"
                                                type="password"
                                                fullWidth
                                                style={{margin: 8}}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.verification}
                                                helperText={((errors.verification && touched.verification) && errors.verification) ||
                                                <FormattedMessage
                                                    id="ADMINISTRATION.USERCREATE.FORM.VERIFICATION.HELPER"/>}
                                                error={errors.verification && touched.verification}
                                            />
                                            <div className={classes.formSubmit}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={buttonClassname}
                                                    disabled={(errors.firstName && touched.firstName) || (errors.lastName && touched.lastName) || (errors.password && touched.password) || (errors.verification && touched.verification) || loading}
                                                    type="submit"
                                                    disableElevation
                                                >
                                                    <FormattedMessage id="ADMINISTRATION.USEREDIT.FORM.BUTTON"/>
                                                </Button>
                                                {loading &&
                                                <CircularProgress size={24} className={classes.buttonProgress}/>}
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </>
    );
}

class ProfileEdit extends React.Component {
    constructor() {
        super();
        this.state = {
            profile: null
        }
    }

    componentDidMount() {
        getUserByToken().then(res => {
            const data = res.data;
            this.setState({profile: data});
        })
    };

    render() {
        return (
            <>
                {this.state.profile === null &&
                <div style={{margin: '0 auto'}}>
                    <CircularProgress/>
                </div>
                }
                {this.state.profile !== null &&
                <ProfileEditView profile={this.state.profile} props={this.props}/>
                }
            </>
        );
    }
}


export default injectIntl(
    connect(
        null,
        auth.actions
    )(ProfileEdit)
);