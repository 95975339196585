import React from 'react';
import { useSelector } from 'react-redux';
import {IntlProvider} from 'react-intl';

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/de";

import deMessages from "./messages/de";
import enMessages from "./messages/en";

const allMessages = {
  de: deMessages,
  en: enMessages,
};

export function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
