import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Error404 } from './pages/404';
import {Error407} from "./pages/407";

export default function ErrorsPage() {
    console.log('error')
  return (
    <Switch>
      <Route path="/error/404" component={Error404} />
        <Route path="/error/407" component={Error407} />
      <Redirect to="/error/404" />
    </Switch>
  );
}
