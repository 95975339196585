import React, {useEffect} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Typography
} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect, useDispatch, useSelector} from "react-redux";


import MuiAlert from "@material-ui/lab/Alert";
import * as snackbar from "../../../../store/reducers/snackbar";
import {putDiscordGrantRole} from "../../../../api/discord";

import {connectBlockchain} from "../../../../utils/blockchain"
import {green} from "@material-ui/core/colors";
import {Image} from "react-bootstrap";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.tertiary.main,
        backgroundImage: 'url(/images/verification_background.jpeg)',
        backgroundSize: "cover",
        height: '100%',
        width: '100vw',
        display: 'flex',
        WebkitBoxAlign: "center",
        alignItems: "center",
        WebkitBoxPack: "justify",
        justifyContent: "center"
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    header: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    },
    card: {
        minWidth: '260px',
        paddingLeft: '24px',
        paddingRight: '24px',
        margin: '24px',
        backgroundColor: theme.palette.quaternary.main
    },
    cardContent: {
        textAlign: "center"
    },
    avatar: {
        width: '140px',
        borderRadius: '24px'
    },
    username: {
        marginBottom: '24px'
    }
}));

function VerificationPage(props) {
    const {intl} = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const blockchainData = useSelector((state) => state.blockchain);

    // Connected
    const [connected, setConnected] = React.useState(false);

    // Connect Button
    const [loading, setLoading] = React.useState(false);

    // Feedback Minter
    const [feedback, setFeedback] = React.useState('MINTER.FEEDBACK.DEFAULT');
    const [feedbackSeverity, setFeedbackSeverity] = React.useState('info');
    const [feedbackRole, setFeedbackRole] = React.useState('');

    // Discord User
    const [discordUser, setDiscordUser] = React.useState({
        id: "",
        avatar: "",
        accessToken: null,
    });
    const [discordUserImage, setDiscordUserImage] = React.useState(null);

    const connectBlockchainWrapper = async() => {
        setLoading(true)
        let result = await connectBlockchain()

        if(result.success === false){
            if(result.network.length > 0) {
                dispatch(snackbar.actions.show(result.type, intl.formatMessage({
                    id: result.id
                }, {
                    network: result.network
                })));
            } else {
                dispatch(snackbar.actions.show(result.type, intl.formatMessage({
                    id: result.id
                })));
            }
        }
    }

    const checkOwnership = async() => {
        if (blockchainData.account !== "" && blockchainData.smartContract !== null) {

            await putDiscordGrantRole({
                discord_user_id: discordUser.id,
                account_address: blockchainData.account,
            }).then((response) => {
                if(response.status === 200) {
                    setFeedback(response.data.id)
                    setFeedbackSeverity('error')

                    if (response.data.success) {
                        setFeedbackSeverity('success')
                        setFeedbackRole(response.data.role)
                    }

                    setConnected(true)
                }
            })
        }
    }

    useEffect(() => {
        // parse access_token and token_type that were inserted by Discord into redirect URL
        const fragment = new URLSearchParams(window.location.hash.slice(1));
        const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];

        const fetchUsers = () => {
            fetch('https://discord.com/api/users/@me', {
                headers: {
                    authorization: `${tokenType} ${accessToken}`,
                },
            })
                .then(result => result.json())
                .then(response => {
                    response.accessToken = accessToken;
                    setDiscordUser(response);

                    fetch('https://cdn.discordapp.com/avatars/' + response.id +'/' + response.avatar + '.png').then(r => {
                        if(r.status === 200) {
                            setDiscordUserImage('https://cdn.discordapp.com/avatars/' + response.id +'/' + response.avatar + '.png')
                        } else {
                            setDiscordUserImage('/images/discord.png')
                        }
                    })
                })
                .catch(console.error);
        };

        if (accessToken) {
            fetchUsers();
        }
    }, []);

    useEffect(() => {
        checkOwnership()
    }, [blockchainData.account]);

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardHeader title={"Verify Ownership"} className={classes.header}/>
                <CardContent className={classes.cardContent}>
                    {connected ? (
                        <Typography variant="caption">
                            <MuiAlert severity={feedbackSeverity} className={classes.feedback}>
                                <FormattedMessage id={feedback} values={{
                                    role: feedbackRole
                                }}/>
                            </MuiAlert>
                        </Typography>
                    ) : (
                        <>
                            {discordUser.accessToken === null ? (
                                <Typography variant="caption">
                                    <MuiAlert severity="error" className={classes.feedback}>
                                        <FormattedMessage id='VERIFICATION.MISSING.DISCORD' />
                                    </MuiAlert>
                                </Typography>
                            ) : (
                                <>
                                    <Image src={discordUserImage} className={classes.avatar}/>
                                    <Typography variant="caption" component="div" className={classes.username}>
                                        {discordUser.username}
                                    </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    type="submit"
                                    disableElevation
                                    onClick={(e) => {
                                        e.preventDefault();
                                        connectBlockchainWrapper();

                                    }}
                                >
                                    <FormattedMessage id="VERIFICATION.CONNECT.BUTTON"/>
                                </Button>
                                </>
                            )}

                        </>
                    )}
                </CardContent>
            </Card>

        </div>
    )
}

export default injectIntl(
    connect(
        null,
        null
    )(VerificationPage)
);