import axios from 'axios';

// get react app api url if hosted somewhere else
const { REACT_APP_API_URL } = process.env;

export const USER_ME = '/api/profile';


export function getUserByToken() {
    return axios.get(USER_ME);
}

export function putUserByToken(user) {
    return axios.put(USER_ME, user);
}

