//  redux-batch (https://github.com/manaflair/redux-batch)
import {reduxBatch} from '@manaflair/redux-batch';

// redux-toolkit (https://github.com/reduxjs/redux-toolkit)
import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';

// redux-saga (https://redux-saga.js.org/)
import createSagaMiddleware from 'redux-saga';

// redux-persist (https://github.com/rt2zz/redux-persist)
import {persistStore} from 'redux-persist';


import {createRootReducer, createRootSaga} from './reducers';

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  }),
  sagaMiddleware
];

const store = configureStore({
  reducer: createRootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch]
});

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

sagaMiddleware.run(createRootSaga);

export default store;
