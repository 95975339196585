import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
    request: "CHECK_DATA_REQUEST",
    success: "CHECK_DATA_SUCCESS",
    failed: "CHECK_DATA_FAILED"
};

const initialState = {
    loading: false,
    totalSupply: 0,
    mintSize: 0,
    nftName: "",
    nftSymbol: "",
    cost: 0,
    errorMsg: "",
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.request:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.success:
            return {
                ...state,
                loading: false,
                totalSupply: action.payload.totalSupply,
                cost: action.payload.cost,
                mintSize: action.payload.mintSize,
                nftName: action.payload.nftName,
                nftSymbol: action.payload.nftSymbol,
                status: action.payload.status,
            };
        case actionTypes.failed:
            return {
                ...initialState,
                loading: false
            };
        default:
            return state;
    }
};

export const actions = {
    fetchDataRequest: message => ({ type: actionTypes.request }),
    fetchDataSuccess: (payload) => ({
        type: actionTypes.success,
        payload: payload
    }),
    fetchDataFailed: () => ({
        type: actionTypes.failed
    })
};