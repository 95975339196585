import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
    display: "SNACKBAR_OPEN",
    clear: "SNACKBAR_CLEAR"
};

const initialState = {
    type: 'info',
    visible: false,
    message: ''
};

export const reducer = persistReducer(
    { storage, key: "snackbar" },
    (state = initialState, action) => {
        switch (action.type) {
            case actionTypes.display: {
                return {
                    ...state,
                    type: action.payload.type,
                    visible: true,
                    message: action.payload.message
                };
            }

            case actionTypes.clear: {
                return {
                    ...state,
                    visible: false,
                };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    show: (type, message) => ({
        type: actionTypes.display,
        payload: {
            type,
            message
        }
    }),
    clear: message => ({ type: actionTypes.clear })
};