import React, {useState} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {connect, useDispatch} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../../../../store/reducers/auth";
import * as snackbar from '../../../../store/reducers/snackbar'
import {login} from "../../../../api/auth";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {CircularProgress} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import clsx from "clsx";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.wikipedia.com">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formSubmit: {
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

function AuthenticationPage(props) {
    const {intl} = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    // Submit Loading
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    return <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                <FormattedMessage id="AUTH.LOGIN.TITLE"/>
            </Typography>
            <Formik
                enableReinitialize
                initialValues={{
                    username: "",
                    password: ""
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string()
                        .required('This field is required.'),
                    password: Yup.string()
                        .required('This field is required.'),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSuccess(false);
                    setLoading(true);

                    setTimeout(() => {
                        login(values.username, values.password)
                            .then(({data: {accessToken}}) => {
                                props.login(accessToken);
                            }).catch(() => {
                            setSuccess(false);
                            setLoading(false);

                            dispatch(snackbar.actions.show("error", intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_LOGIN"
                            })));
                        });
                    }, 1000);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                  }) => {
                    return (
                        <form className={classes.form} noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label={<FormattedMessage id="ADMINISTRATION.USERCREATE.FORM.USERNAME"/>}
                                name="username"
                                autoComplete="username"
                                autoFocus
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.username}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={<FormattedMessage id="ADMINISTRATION.USERCREATE.FORM.PASSWORD"/>}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                            />
                            <FormControlLabel
                                control={<Checkbox value="ldap" color="primary"/>}
                                label="LDAP"
                            />
                            <div className={classes.formSubmit}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={buttonClassname}
                                    disabled={(errors.username && touched.username) || (errors.password && touched.password) || loading}
                                    type="submit"
                                    disableElevation
                                    fullWidth
                                >
                                    <FormattedMessage id="AUTH.LOGIN.BUTTON"/>
                                </Button>
                                {loading &&
                                <CircularProgress size={24} className={classes.buttonProgress}/>}
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
        <Box mt={8}>
            <Copyright/>
        </Box>
    </Container>
}

export default injectIntl(
    connect(
        null,
        auth.actions
    )(AuthenticationPage)
);