import * as contract from "../store/reducers/contract";
import store from "../store/store";
import React from "react";

export const getMinterInformation = async () => {
    store.dispatch(contract.actions.fetchDataRequest());

    let result = {
        id: '',
        type: '',
        success: false
    }

    try {
        let totalSupply = await store
            .getState()
            .blockchain.smartContract.methods.totalSupply()
            .call();
        let mintSize = await store
            .getState()
            .blockchain.smartContract.methods.minterMaximumCapacity()
            .call();
        let nftName = await store
            .getState()
            .blockchain.smartContract.methods.name()
            .call();
        let nftSymbol = await store
            .getState()
            .blockchain.smartContract.methods.symbol()
            .call();
        let cost = await store
            .getState()
            .blockchain.smartContract.methods.minterFees()
            .call();
        let status = await store
            .getState()
            .blockchain.smartContract.methods.minterStatus()
            .call();

        store.dispatch(
            contract.actions.fetchDataSuccess({
                totalSupply,
                mintSize,
                nftName,
                nftSymbol,
                cost,
                status
            })
        );

        result.id = 'BLOCKCHAIN.ACCOUNT.CONNECTED';
        result.type = 'success'
        result.success = true

        return result

    } catch (err) {
        store.dispatch(contract.actions.fetchDataFailed());

        result.id = 'SMARTCONTRACT.LOADING.FAILED';
        result.type = 'error'
        result.success = false

        return result
    }

}

