import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getUserByToken } from '../../api/profile';

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  VerifyAuth: "[Verify Auth] Verify Authentication",
  SetRole: "[Role] Set Role",
  RoleLoaded: "[Load Role] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  role: undefined
};

export const reducer = persistReducer(
  { storage, key: "MERN-Skeleton", whitelist: ["user", "role", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.VerifyAuth: {
        return state;
      }

      case actionTypes.RoleLoaded: {
        const { role } = action.payload;
        return { ...state, role};
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  verify: authToken => ({ type: actionTypes.VerifyAuth, payload: { authToken }}),
  fulfillRole: role => ({ type: actionTypes.RoleLoaded, payload: {role}}),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
    yield put(actions.fulfillRole(user.role));
  });

  yield takeLatest(actionTypes.VerifyAuth, function* userVerify() {
    try {
      const {data: user} = yield getUserByToken();
      yield put(actions.fulfillUser(user));
      yield put(actions.fulfillRole(user.role));
    }
    catch  {
      yield put(actions.logout());
    }
  });
}
