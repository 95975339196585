import { Requirement, guardFactory, not, any } from 'react-rbac-guard';

class Need extends Requirement {
  constructor(credentials) {
    super();
    this.credentials = credentials;
  }

  isSatisfied(credentials) {
    return this.credentials === credentials;
  }
}

const NeedAdmin = new Need("admin");
const NeedManager = new Need("manager");
const NeedUser = new Need("user");
const NeedGuest = new Need("guest");

export const NeedAuthorized = any(NeedAdmin, NeedManager, NeedUser);
export const NeedExtendedAuthorization = any(NeedAdmin, NeedManager);

export const GuardAdmin = guardFactory(any(NeedAdmin));
export const GuardManager = guardFactory(any(NeedManager, NeedAdmin));
export const GuardUser = guardFactory(not(NeedGuest));
export const GuardGuest = guardFactory(NeedGuest);

export const GuardNotGuest = guardFactory(not(NeedGuest));
export const GuardNotAdmin = guardFactory(not(NeedAdmin));