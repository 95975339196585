import React from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {actions} from "../../../../../store/reducers/i18n";
import {Toolbar} from "@material-ui/core";
import {green} from "@material-ui/core/colors";


const useStyles = makeStyles((theme) => ({
    listItemIcon: {
        minWidth: 0
    },
    itemIcon: {
        borderRadius: '10px'
    },
    selectItemIcon: {
        borderRadius: '10px',
        marginRight: '7px'
    },
    listSocial: {
        float: "left"
    },
    walletmenu: {
        backgroundColor: theme.palette.primary.light,
        maxHeight: '48px',
        minHeight: '38px',
        display: 'flex',
        WebkitBoxAlign: "center",
        alignItems: "center",
        WebkitBoxPack: "justify",
        justifyContent: "center"
    },
    walletaddressNotConnected: {
        backgroundColor: theme.palette.primary.dark,
        borderRadius: '3px',
        paddingRight: '8px',
        paddingLeft: '8px',
        paddingTop: '3px',
        paddingBottom: '3px'
    },
    walletaddressConnected: {
        backgroundColor: green[500],
        borderRadius: '3px',
        paddingRight: '8px',
        paddingLeft: '8px',
        paddingTop: '3px',
        paddingBottom: '3px'
    }
}));

function WalletMenu(props) {
    const classes = useStyles();
    const { walletAddress } = props;

    return (
        <Toolbar className={classes.walletmenu}>
            <div className={classes.root}>
                {walletAddress === null ? (
                    <div className={classes.walletaddressNotConnected}>Not connected</div>
                ) : (
                    <div className={classes.walletaddressConnected}>Wallet: {walletAddress.substr(0,5)}...{walletAddress.substr(38,42)}</div>
                )}
            </div>
        </Toolbar>
    );
}

const mapStateToProps = ({ blockchain }) => ({ walletAddress: blockchain.account });

export default connect(
    mapStateToProps,
    actions
)(WalletMenu);