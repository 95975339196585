import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import {LastLocationProvider} from 'react-router-last-location';

// i18 Plugin
import {I18nProvider} from '../../i18n';
import {MaterialUiSnackbar} from '../../snackbar'

// Core Layout
import {MaterialTheme, SplashScreen} from '../../layout';

import Routes from '../../environment/Routes';

export default function Root({store, persistor, url}) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<SplashScreen/>}>
        <React.Suspense fallback={<SplashScreen/>}>
          <BrowserRouter basename={url}>
            <LastLocationProvider>
              <MaterialTheme>
                <I18nProvider>
                  <Routes />
                  <MaterialUiSnackbar />
                </I18nProvider>
              </MaterialTheme>
            </LastLocationProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired
};