export default function axiosInit(axios, store) {
    axios.interceptors.request.use(
        config => {
            const {
                auth: {authToken}
            } = store.getState();

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }

            return config;
        },
        err => Promise.reject(err)
    );

    axios.interceptors.response.use(function (response) {

        return response;
    }, function (error) {
        return Promise.reject(error.response);
    });
}
