import axios from 'axios';

// get react app api url if hosted somewhere else
const { REACT_APP_API_URL } = process.env;

export const USER_LIST = '/api/users'
export const USER_GET = '/api/users';
export const USER_PUT = '/api/users';
export const USER_DELETE = '/api/users';
export const USER_CREATE = '/api/users/create';

export function getUsers() {
  return axios.get(USER_LIST);
}

export function getUserById(userId) {
  return axios.get(USER_GET + '/' + userId);
}

export function putUserCreate(user) {
  return axios.put(USER_CREATE, user);
}

export function putUserEdit(user) {
  return axios.put(USER_PUT + '/' + user.id, user);
}

export function deleteUser(user) {
  return axios.delete(USER_DELETE + '/' + user.id);
}
